import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
// import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>,
    // document.getElementById('root')
    <React.StrictMode>
        <BrowserRouter> 
            <App />   
        </BrowserRouter> 
    </React.StrictMode>
);
